import React, { useState, useEffect, useRef } from 'react';
import { Projects, Shorts } from '../../api/project';
import { Link } from "react-router-dom";
import { Slide } from "react-awesome-reveal";
import Isotope from 'isotope-layout';
import imagesLoaded from 'imagesloaded';
import YoutubeEmbed from './YoutubeEmbeded';

const ProjectSectionS5 = () => {
  const longVideoContainerRef = useRef(null);
  const shortsContainerRef = useRef(null);
  const longVideoIso = useRef(null);
  const shortsIso = useRef(null);
  const [activeLongVideoFilter, setActiveLongVideoFilter] = useState('.talking'); // Default filter for long videos
  const [activeShortsFilter, setActiveShortsFilter] = useState('.head'); // Default filter for shorts

  useEffect(() => {
    imagesLoaded(longVideoContainerRef.current, function () {
      longVideoIso.current = new Isotope(longVideoContainerRef.current, {
        itemSelector: '.filter-item',
        layoutMode: 'fitRows',
      });
      longVideoIso.current.arrange({ filter: activeLongVideoFilter }); // Apply the default filter on load
    });
    imagesLoaded(shortsContainerRef.current, function () {
      shortsIso.current = new Isotope(shortsContainerRef.current, {
        itemSelector: '.filter-item',
        layoutMode: 'fitRows',
      });
      shortsIso.current.arrange({ filter: activeShortsFilter }); // Apply the default filter on load
    });
    return () => {
      if (longVideoIso.current) longVideoIso.current.destroy();
      if (shortsIso.current) shortsIso.current.destroy();
    };
  }, [activeLongVideoFilter, activeShortsFilter]); // Ensure useEffect is called when filter changes

  const handleLongVideoFilterClick = (filterValue) => {
    setActiveLongVideoFilter(filterValue);
    longVideoIso.current.arrange({ filter: filterValue });
  };

  const handleShortsFilterClick = (filterValue) => {
    setActiveShortsFilter(filterValue);
    shortsIso.current.arrange({ filter: filterValue });
  };

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  const extractVideoId = (link) => {
    const videoIdMatch = link.match(/(?:\/|v=)([a-zA-Z0-9_-]{11})/);
    return videoIdMatch ? videoIdMatch[1] : null;
  };

  return (
    <div className="porfolio-page">
      <div className="project-section-s2 section-padding">
        {/* long form */}
        <div className="container">
          <div className="row" style={{width:"80vw"}}>
            <h1 style={{ textAlign: "center", color: "white" }}>Long video</h1>
            <div className="col col-xs-12 sortable-gallery">
              <div className="portfolio-filters">
                <ul>
                  <li>
                    <button onClick={() => handleLongVideoFilterClick('.talking')} className={activeLongVideoFilter === '.talking' ? 'active' : ''}>Talking head</button>
                  </li>
                  <li>
                    <button onClick={() => handleLongVideoFilterClick('.Docs')} className={activeLongVideoFilter === '.Docs' ? 'active' : ''}>Documentaries</button>
                  </li>
                  <li>
                    <button onClick={() => handleLongVideoFilterClick('.fav')} className={activeLongVideoFilter === '.fav' ? 'active' : ''}>Creator style</button>
                  </li>
                </ul>
              </div>
              <div className="portfolio-container portfolio-fancybox masonry-gallery row gallery-container" ref={longVideoContainerRef}>
                {Projects.map((project, srv) => (
                  <div className={`col-lg-6 col-md-6 col-12 grid-item filter-item ${project.filterName}`} key={srv}>
                    <Slide direction='up' triggerOnce={false}>
                      <div className="project-item">
                        <div className="project-img">
                          <YoutubeEmbed className="video_frame" link={project.link} />
                        </div>
                        <div className="project-text">
                          <div className="project-left">
                            <h2><Link onClick={ClickHandler} to={`/project-single/${extractVideoId(project.link)}`}>{project.title}</Link></h2>
                            <span>{project.sub}</span>
                          </div>
                          <div className="arrow-btn">
                            <Link onClick={ClickHandler} to={`/project-single/${extractVideoId(project.link)}`}><i className="ti-arrow-top-right"></i></Link>
                          </div>
                        </div>
                      </div>
                    </Slide>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* short form */}
      <div className="project-section-s2 section-padding">
        <div className="container">
          <div className="row" style={{width:"80vw"}}>
            <h1 style={{ textAlign: "center", color: "white" }}>Shorts video</h1>
            <div className="col col-xs-12 sortable-gallery">
              <div className="portfolio-filters">
                <ul>
                  <li>
                    <button onClick={() => handleShortsFilterClick('.head')} className={activeShortsFilter === '.head' ? 'active' : ''}>Talking Head</button>
                  </li>
                  <li>
                    <button onClick={() => handleShortsFilterClick('.podcast')} className={activeShortsFilter === '.podcast' ? 'active' : ''}>Podcast</button>
                  </li>
                  <li>
                    <button onClick={() => handleShortsFilterClick('.creator')} className={activeShortsFilter === '.creator' ? 'active' : ''}>Creator style</button>
                  </li>
                </ul>
              </div>
              <div className="portfolio-container portfolio-fancybox masonry-gallery row gallery-container" ref={shortsContainerRef}>
                {Shorts.map((project, srv) => (
                  <div className={`col-lg-6 col-md-6 col-12 grid-item filter-item ${project.filterName}`} key={srv}>
                    <Slide direction='up' triggerOnce={false}>
                      <div className="project-item">
                        <div className="project-img">
                          <YoutubeEmbed className="video_frame" link={project.link} isShort={true} />
                        </div>
                        <div className="project-text">
                          <div className="project-left">
                            <h2><Link onClick={ClickHandler} to={`/project-single/${extractVideoId(project.link)}`}>{project.title}</Link></h2>
                            <span>{project.sub}</span>
                          </div>
                          <div className="arrow-btn">
                            <Link onClick={ClickHandler} to={`/project-single/${extractVideoId(project.link)}`}><i className="ti-arrow-top-right"></i></Link>
                          </div>
                        </div>
                      </div>
                    </Slide>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectSectionS5;
