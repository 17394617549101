import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import logo from '../../images/logo.png'
import MobileMenu from '../MobileMenu/MobileMenu';
import { Projects, Shorts } from '../../api/project';
import YoutubeEmbed from '../ProjectSectionS5/YoutubeEmbeded';

const Header = (props) => {

    const [menuActive, setMenuState] = useState(false);
    const [rightActive, setRightState] = useState(false);

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    const SubmitHandler = (e) => {
        e.preventDefault()
    }

    const extractVideoId = (link) => {
        const videoIdMatch = link.match(/(?:\/|v=)([a-zA-Z0-9_-]{11})/);
        return videoIdMatch ? videoIdMatch[1] : null;
      };
  

    return (
        <header className={`header-area ${props.hclass}`}>
            <div id="header-sticky" className="menu-area">
                <div className="container-fluid">
                    <div className="second-menu">
                        <div className="row align-items-center">
                            <div className="col-xl-2 col-lg-2 col-md-7 col-7">
                                <div className="logo">
                                    <Link onClick={ClickHandler} to="/home"><img src={logo} alt="logo" style={{"width":"7vw"}} /></Link>
                                </div>
                            </div>
                            <div className="col-xl-8 col-lg-8 col-md-1 col-1 text-right text-xl-right d-none d-lg-block">
                                <div className="main-menu">
                                    <nav id="mobile-menu">
                                        <ul className="nav">
                                            <li className="has-submenu">
                                                <Link onClick={ClickHandler} to="/home">Home</Link>
                                               
                                            </li>
                                            {/* <li className="has-submenu"><Link onClick={ClickHandler} to="/about-us">_about</Link>
                                               
                                            </li> */}
                                            <li className="has-submenu"><Link onClick={ClickHandler} to="/portfolio">Portfolio</Link>
                                                {/* <ul className="sub-menu">
                                                    <li><Link onClick={ClickHandler} to="/portfolio">Portfolio Grid</Link></li>
                                                    <li><Link onClick={ClickHandler} to="/portfolio-s2">Portfolio Grid S2</Link></li>
                                                    <li><Link onClick={ClickHandler} to="/portfolio-s3">Portfolio Grid S3</Link></li>
                                                    <li><Link onClick={ClickHandler} to="/project-single/Business">Portfolio Single</Link></li>
                                                </ul> */}
                                            </li>
                                            {/* <li className="has-submenu">
                                                <Link onClick={ClickHandler} to="/blog-right-sidebar">_blog</Link>
                                               
                                            </li> */}
                                            <li><Link onClick={ClickHandler} to="/contact">Contact</Link></li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                            <div className="col-xl-2 col-lg-2 col-md-4 col-3 text-left">
                                <div className="header-area-right">
                                    <ul>
                                        {/* <li><Link onClick={ClickHandler} to="/" className="search-toggle-btn"><i className="ti-search" onClick={() => setMenuState(!menuActive)}></i></Link>
                                        </li> */}
                                        <li className="header-right-menu-wrapper">
                                            <div className="right-menu-toggle-btn">
                                                <div className="right-menu-toggle-btn-wrap" onClick={() => setRightState(!rightActive)}>
                                                    <span></span>
                                                    <span></span>
                                                    <span></span>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-1">
                                <MobileMenu />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`header-search-form ${menuActive ? "active" : ""}`}>
                <button className="close-header-search" onClick={() => setMenuState(!menuActive)}><i className="ti-close"></i></button>
                <form method="post" onSubmit={SubmitHandler}>
                    <div className="form-group">
                        <input type="text" name="search" value="" placeholder="Search Here" required="" />
                        <button type="submit" className="search-btn"><i className="ti-search"></i></button>
                    </div>
                </form>
            </div>
            <div className={`header-right-menu-wrap ${rightActive ? "right-menu-active" : ""}`}>
                <div className="all-close" onClick={() => setRightState(!rightActive)}></div>
                <button className="right-menu-close" onClick={() => setRightState(!rightActive)}><i className="ti-close"></i></button>
                <div className="logo"><img src="assets/images/logo.svg" alt="" /></div>
                <div className="header-right-sec">
                    <div className="project-widget widget">
                        <h3>Our Latest Projects</h3>
                        <ul className="d-flex">
                            {Projects.slice(0, 6).map((project, srv) => (
                                <li key={srv}><Link onClick={ClickHandler} to={`/project-single/${extractVideoId(project.link)}`}>  <YoutubeEmbed className="video_frame" link={project.link} /></Link></li>
                            ))}
                        </ul>
                    </div>
                    <div className="widget contact-widget">
                        <div className="widget-title">
                            <h3>Contact Us</h3>
                        </div>
                        <div className="contact-ft">
                            <ul>
                                <li><i className="fi flaticon-placeholder"></i>Neelbad road, 462044<br /> Bhopal, India</li>
                                <li><i className="fi flaticon-phone-call"></i>+91 9174152696 <br />
                                    +91 9893519285</li>
                                <li><i className="fi flaticon-email"></i>contact@cloudpsax.com</li>
                            </ul>
                        </div>
                    </div>
                    <div className="widget newsletter-widget">
                        <div className="widget-title">
                            <h3>Newsletter</h3>
                        </div>
                        <form onSubmit={SubmitHandler}>
                            <div className="input-1">
                                <input type="email" className="form-control" placeholder="Email Address *" required="" id="femail" />
                                <div className="submit clearfix">
                                    <button type="submit"><i className="ti-arrow-top-right"></i></button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header;