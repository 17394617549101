import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import "./VideoShow.css";

const VideoShow = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // Disable autoplay to control manually
    fade: true,
    beforeChange: (oldIndex, newIndex) => handleBeforeChange(newIndex)
  };

  const slideTexts = [
    "): Boring video..?",
    "): Now what do you say?"
  ];

  useEffect(() => {
    const script1 = document.createElement('script');
    script1.src = 'https://fast.wistia.com/embed/medias/huftscjv2a.jsonp';
    script1.async = true;

    const script2 = document.createElement('script');
    script2.src = 'https://fast.wistia.com/assets/external/E-v1.js';
    script2.async = true;

    document.body.appendChild(script1);
    document.body.appendChild(script2);

    return () => {
      document.body.removeChild(script1);
      document.body.removeChild(script2);
    };
  }, []);

  useEffect(() => {
    if (window.Wistia) {
      window._wq = window._wq || [];
      window._wq.push({
        id: "a101pqazpq",
        onReady: function (video) {
          window.firstVideo = video;
        }
      });
      window._wq.push({
        id: "huftscjv2a",
        onReady: function (video) {
          window.secondVideo = video;
        }
      });
    }
  }, []);

  const handleBeforeChange = (newIndex) => {
    setCurrentSlide(newIndex);
    if (newIndex === 0) {
      if (window.firstVideo) {
        window.firstVideo.play();
        setTimeout(() => {
          window.firstVideo.pause();
          document.querySelector('.slick-next').click();
        }, 3000); // Play the first video for 3 seconds
      }
    } else if (newIndex === 1) {
      if (window.secondVideo) {
        window.secondVideo.play();
        setTimeout(() => {
          window.secondVideo.pause();
          document.querySelector('.slick-prev').click();
        }, 30000); // Play the second video for 30 seconds
      }
    }
  };

  return (
    <div className="hero-container">
      <div className="hero-text">{slideTexts[currentSlide]}</div>
      <div className="slider-container">
        <Slider {...settings}>
          <div className="slide">
            <div className="wistia_responsive_padding" style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
              <div className="wistia_responsive_wrapper" style={{ height: '100%', left: 0, position: 'absolute', top: 0, width: '100%' }}>
                <div className="wistia_embed wistia_async_a101pqazpq seo=true videoFoam=true" style={{ height: '100%', position: 'relative', width: '100%' }}>&nbsp;</div>
              </div>
            </div>
          </div>
          <div className="slide">
            <div className="wistia_responsive_padding" style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
              <div className="wistia_responsive_wrapper" style={{ height: '100%', left: 0, position: 'absolute', top: 0, width: '100%' }}>
                <div className="wistia_embed wistia_async_huftscjv2a seo=true videoFoam=true" style={{ height: '100%', position: 'relative', width: '100%' }}>&nbsp;</div>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </div>
  );
};

export default VideoShow;
