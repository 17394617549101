import React, { Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Projects, Shorts } from '../../api/project';
import Header from '../../components/header/Header';
import Footer2 from '../../components/footer2/Footer2';
import PageTitle from '../../components/pagetitle/PageTitle';
import Scrollbar from '../../components/scrollbar/scrollbar';
import YoutubeEmbed from '../../components/ProjectSectionS5/YoutubeEmbeded';

const ClickHandler = () => {
    window.scrollTo(10, 0);
};

const ProjectSinglePage = () => {
    const { slug } = useParams();

    // Find the project or short that matches the slug in the link
    const matchedProject = Projects.find(project => project.link.includes(slug));
    const matchedShort = Shorts.find(short => short.link.includes(slug));

    // Combine the found project or short into a single object
    const PracticeDetails = matchedProject || matchedShort;

    if (!PracticeDetails) {
        return <div>Project not found</div>; // Handle the case where no matching project is found
    }

    // Filter related projects with the same category and exclude the current project
    const relatedProjects = Projects.filter(project => project.filterName === PracticeDetails.filterName && project.slug !== PracticeDetails.slug);
    console.log("practice details",PracticeDetails.youtube);

    return (
        <Fragment>
            <div className="dark-page">
                <Header />
                <PageTitle pageTitle={PracticeDetails.client} pagesub={'Project'} />
                <div className="porfolio-single-page">
                    <div className="portfolio-details-wrap section-padding">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-7">
                                    <div className="portfolio-details-img">
                                        <YoutubeEmbed
                                            className="video_frame"
                                            link={`https://www.youtube.com/embed/${slug}`}
                                            isShort={true}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                    <div className="portfolio-details-text">
                                        <h2>{PracticeDetails.client}</h2>
                                        <div className="portfolio-text-items">
                                            <div className="portfolio-text-item">
                                                <h4>Client:</h4>
                                                <span>{PracticeDetails.client}</span>
                                            </div>
                                            <div className="portfolio-text-item">
                                                <h4>Joined:</h4>
                                                <span>{PracticeDetails.doj}</span>
                                            </div>
                                            <div className="portfolio-text-item">
                                                <h4>Niche:</h4>
                                                <span>{PracticeDetails.niche}</span>
                                            </div>
                                            <div className="portfolio-text-item">
                                                <h4>Share:</h4>
                                                <div className="social">
                                                <a href={PracticeDetails.insta} target="_blank" rel="noopener noreferrer">In</a>
                                                    <a href={PracticeDetails.youtube} target="_blank" rel="noopener noreferrer">youtube</a>
                                                </div>
                                            </div>
                                        </div>
                                        <p>Proin efficitur, mauris vel condimentum pulvinar, velit orci consectetur ligula, eget egestas magna mi ut arcu. Phasellus nec odio orci. Nunc id massa ante. Suspendisse sit amet neque euismod, convallis quam eget, dignissim massa.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="recent-project-section section-padding pt-0">
                        <div className="container">
                            <div className="section-title-s3">
                                <span style={{ "color": "white" }}>Recent Portfolio</span>
                                <h2 style={{ "color": "white" }}>Related Work</h2>
                            </div>
                            <div className="project-active-s2">
                                <div className="row">
                                    {relatedProjects.map((project, srv) => (
                                        <div className="col-lg-4 col-md-6 col-12" key={srv}>
                                            <div className={`project-item s-${project.Id}`}>
                                                <div className="project-img">
                                                    <YoutubeEmbed
                                                        className="video_frame"
                                                        link={project.link}
                                                        isShort={true}
                                                    />
                                                </div>
                                                <div className="project-text">
                                                    <h2>
                                                        <Link onClick={ClickHandler} to={`/project-single/${project.slug}`}>
                                                            {project.title}
                                                        </Link>
                                                    </h2>
                                                    <span style={{ "color": "white" }}>{project.sub}</span>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer2 />
                <Scrollbar />
            </div>
        </Fragment>
    );
};

export default ProjectSinglePage;
