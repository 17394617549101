import React from "react";
import PropTypes from "prop-types";

const YoutubeEmbed = ({ link, isShort }) => {
  const videoSrc = isShort ? `${link}` : link;
  console.log("shorts",videoSrc);

  return (
    <div className="video-responsive">
      <iframe
        width="100%"
        height="280"
        src={videoSrc}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
        //  style={{
        //   // Apply different styles for mobile devices using media query
        //   "@media only screen and (max-width: 600px)": {
        //     width: "100vw", // Set width to 100% for smaller screens
        //     height: "200px" // Set height to 200px for smaller screens
        //   }
        // }}
      />
    </div>
  );
};

YoutubeEmbed.propTypes = {
  link: PropTypes.string.isRequired,
  isShort: PropTypes.bool,
};

YoutubeEmbed.defaultProps = {
  isShort: false,
};

export default YoutubeEmbed;
